if ( $( "#vacancies-gallery" ).length ) { 

    const button = $( "#load-more-vacancies" );
    const layout = $( "#vacancies-gallery" ).attr( "data-layout" );
    const scrollLoad = $( "#vacancies-gallery" ).attr( "data-scroll" );

    function update_page(layout) {
        let vacancies = $( ".vacancy.hide" );
        let i = 0;
        vacancies.each(function() {
            if ( i < layout ) {
                $(this).removeClass( "hide" );
            }
            i++;
        });
        if (vacancies.length <= layout ) {
            button.addClass( "hide" );
        }
    }

    update_page(layout);

    //update on click
    if ( !scrollLoad ) {
        button.on('click', function(e) {
            e.preventDefault();
            update_page(layout);
        });
    }

    //update on scroll
    if ( scrollLoad ) {

        const winCached = $(window);
        const scrHeight = $(window).height();    
        const elmOffset = $(button).offset().top;
        const bottomOffset = 300; //trigger function 300px above bottom of the screen

        function addContent( elmPosition ) {

            let currLeng = 0;

            dettachScrollEvent();
            setTimeout(function() {
                for (var i = currLeng; i < currLeng + 100; i++)
                update_page(layout);
                currLeng = i;
                attachScrollEvent( elmPosition );
            }, 500); //add 500ms delay between triggering next sequence
        }
      
        function attachScrollEvent( elmPosition) {
            winCached.scroll(function() {
                if ( elmPosition < 0 ) {
                    addContent();
                }
            });
        }
            
        function dettachScrollEvent() {
            winCached.unbind('scroll');
        }
      
        winCached.scroll(function() {
            const elmPosition = elmOffset - $(window).scrollTop() - scrHeight + bottomOffset;
            if ( elmPosition < 0 ) {
                addContent( elmPosition );
            }
        }); 
    } 
}